// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
//require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
//require("jquery");

//require("@fortawesome/fontawesome-free/js/all");

// toastを使用するための対処
global.toastr = require("toastr");
global.toastr.options = {
  closeButton: true,
  positionClass: "toast-top-full-width",
  timeOut: "5000",
  extendedTimeOut: "3000",
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


//
// アクセスログ記録用関数 
//
window.SendAccessLog = function( self, sendPath, contentId, actionName, linkPath, viewKey ){

  $(self).attr("disabled", true );

  let data = {
    content_id: contentId,
    action_name: actionName,
    z_link_path: linkPath,
    view_key: viewKey
  }

  // ajaxでpostするときにcsrfトークンも送信するように設定します。
  {
    $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      if (!options.crossDomain) {
        const token = $('meta[name="csrf-token"]').attr('content');
        if (token) {
          return jqXHR.setRequestHeader('X-CSRF-Token', token);
        }
      }
    });
  }
  
  $.ajax({
    type: "post",
    url: sendPath,
    data: JSON.stringify(data),
    contentType: 'application/json',
    dataType: "text",
    success: (json_data) => {
      // 200 OK時
    },
    error: (XMLHttpRequest, textStatus, errorThrown) => {
      // HTTPエラー時
      console.error("XMLHttpRequest : " + XMLHttpRequest.status);
      console.error("textStatus     : " + textStatus);
      console.error("errorThrown    : " + errorThrown.message);
    },
    complete: () => {
      // 成功・失敗に関わらず通信が終了した際の処理
      $(self).attr("disabled", false);  // ボタンを再びenableにする
    }
  });
};
